/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var video_ended = false,
      video_is_active = true,
      page_imgs_loaded = false,
      page_has_tlc = false,
      bc_active = false,      //breadcrubms active
      elems = {
        'hotspot_anchor': $('.hotspot-anchor'),
        'nav_breadcrumbs': $('nav.page-breadcrumbs'),
        'page_img': $('img.page-image'),
        'preloader': $('div.preloader'),
        'mobile_hotspot_container_outer': $('div.mobile-hotspot-container-outer'),
        'mobile_hotspot_container': $('div.mobile-hotspot-container'),
        'page_nav_back': $('a.page-back'),
        'filter': $('div.filter'),
        'filter_item' : $('div.filter ul li a'),
      };


  /**
   * Set the video played cookie
   * @param name
   * @param value
   * @param days
   */
  function set_cookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toGMTString();

      // var date = new Date();
      // date.setTime(date.getTime()+(days*60*1000));
      // expires = "; expires="+date.toGMTString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/;SameSite=none;Secure";
  }


  /**
   * Get the cookie
   * @param name
   * @returns {string|null}
   */
  function get_cookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }//end get_cookie


  /**
   * Find the hash to load the appropriate background image.
   *
   * Called from:
   *   - hash_change()
   *
   * @returns {string}
   */
  function get_page_hash () {
    var hash = window.location.hash;

    //No hash? Load the first image
    if (typeof hash === 'undefined' || !hash.length) {
      hash = $('span.img-wrap img:first-of-type').data('img-id');
    }

    hash = hash.replace('#', '');

    return hash;
  }//end get_page_hash


  /**
   * Set the hotspot positions based on the image width and height
   *
   *   - Called from hash_change
   */
  function set_hotspot_locations () {

    var anchors = elems.hotspot_anchor;
    var img = $('img.page-image.active-img');
    var img_w = parseInt(img.width());
    var img_h = parseInt(img.height());

    anchors.each(function(){
      var $this = $(this);
      var data_top = parseInt($this.data('top'));
      var data_left = parseInt($this.data('left'));

      var position_top = (data_top * img_h) / 100;
      var position_left = (data_left * img_w) / 100;

      //Adjust for hotspot width:
      position_left -= (parseInt($this.width()) / 2);
      position_top -= (parseInt($this.height()) / 2);

      $this.css({'top':position_top, 'left':position_left});
    });

  }//end set_hotspot_locations


  /**
   * Called after show_active_hotspots
   *
   *  STO (based on total time to animate in all hotspots)
   *    - Check if the any of the hotspots on this page include a filter class. If one doesn't then set that filter to disabled.
   *    - If all filters are disabled, then don't show the filters at all.
   *
   * @param timeout
   */
  function set_and_show_filter (timeout) {

    //Remove any disabled classes from filter items
    elems.filter_item.removeClass('disabled');

    setTimeout(function () {

      //Count the number of disabled filter buttons. If the same as total count then do not show filters at all
      var total_filters_count = elems.filter_item.length,
          filters_disabled_count = 0;

      //Loop the filter items and get the href (cat class).
      //For each cat class, if no hotspots with this class. Then add inactive class to filter item.
      elems.filter_item.each(function(){
        var cat_class = $(this).attr('href');

        //If ACTIVE hotspots DON'T have a class of THIS filter set as DISABLED
        if (!$('.hotspot-anchor:not(.inactive)').hasClass(cat_class)) {
          $(this).addClass('disabled');
          filters_disabled_count++;
        }
      });

      //DO not show filters if all have been disabled, or there is only ONE active filter on this page.
      if (filters_disabled_count === total_filters_count || ((total_filters_count - filters_disabled_count) === 1)) {
        elems.filter.addClass('no-categories');
      } else {
        elems.filter.addClass('filter-active').removeClass('no-categories');
      }

    }, (timeout + 1200));

  }//end set_and_show_filter


  /**
   * Loop each hotspot with a class matching the active image data attr
   *   - Animate in, then trigger the show filter
   *
   */
  function show_active_hotspots () {

    //find the active image:
    var img = $('img.active-img');

    //get the data id
    var data_id = img.data('img-id');

    //Remove the inactive class from the active hotspots
    if ($('.hotspot-anchor.'+data_id).length) {

      //Get number of hotspots, then calc the total interval.
      //Show the filter AFTER total interval
      var hs_count = $('.hotspot-anchor.'+data_id).length;
      var total_interval = (hs_count * 150);

      var interval = 0;
      $('.hotspot-anchor.'+data_id).each(function(){
        var $this = $(this);
        setTimeout(function () {
          $this.removeClass('inactive').addClass('hs-show');

          setTimeout(function () {
            $this.removeClass('hs-show');
          }, 1200);
        }, interval);
        interval += 150;
      });

      set_and_show_filter(total_interval);

    }//end if hotspot len

  }//end show_active_hotspots


  /**
   * Show top level content on screen load.
   *
   * If we've already shown the house TLC a cookie will be set, don't show it and trigger active hotspots
   */
  function show_page_tlc() {

    var hash = get_page_hash();

    //If we've shown the TLC on the house page once, don't show it again.
    if ($('img.page-image.active-img').data('img-id') === 'house') {

      var house_tlc_shown = get_cookie('house_tlc_shown');

      if (!house_tlc_shown) {
        $('.tlc-'+hash).addClass('show');
      } else {
        show_active_hotspots();
      }

    } else {
      $('.tlc-'+hash).addClass('show');
    }

  }//end show_page_tlc


  /**
   * Hash change
   *
   *  - Called from home_init()
   *
   *  - Hide the breadcrumbs, buttons, hotspots, filter
   *  - Check new page for TLC
   *     - If it doesn't have TLC, and a TLC elem is open, close it.
   *
   *  - Get the current image, and the new image.
   *    - Set the new image
   *    - Add classes to old and new to transition out / in
   *
   *  - Set time out, and when the images have loaded set the hotspot locations
   *  - Hide the preloader
   *  - Show TLC or hotspots
   *
   */
  function hash_change () {

    //Hide mobile hotspot container
    reset_mobile_info_boxes();

    //Hide the breadcrums
    elems.nav_breadcrumbs.removeClass('active hs-show');
    bc_active = false;

    //Hide the filter:
    elems.filter.removeClass('filter-active').removeClass('no-categories');

    //Hide the back button
    elems.page_nav_back.removeClass('active');

    //Hide the hotspots
    elems.hotspot_anchor.addClass('inactive').removeClass('hs-show');

    var hash = get_page_hash();

    //Check if active page has TLC, if it does then show this, not hotspots
    if ($('.tlc-'+hash).length && !$('.tlc-'+hash).hasClass('shown-once')) {
      page_has_tlc = true;
    } else {
      page_has_tlc = false;

      //If there is an active TLC then close it.
      $('.top-level-content-outer.show span.tlc-close').trigger('click');
    }


    var active_img = $('img.page-image.active-img');
    var new_img = $('img'+hash);
    elems.page_img.each(function(){
      var $this = $(this);
      if ($this.data('img-id') === hash) {
        new_img = $this;
        return false;
      }
    });

    active_img.addClass('img-out');
    new_img.addClass('img-in');

    setTimeout(function () {
      active_img.removeClass('active-img img-out');
      new_img.addClass('active-img').removeClass('img-in');

      //Wait for the images to fully load and then position the hotspots.
      if (!page_imgs_loaded) {
        imagesLoaded('img.page-image.active-img', {background: false}, function () {
          set_hotspot_locations();
          page_imgs_loaded = true;
        });
      }


      //Hide the preloader if !video_active and show active hotspots
      if (!video_is_active) {
        video_is_active = true;
        hide_preloader();

        setTimeout(function () {
          if (page_has_tlc) {
            show_page_tlc();
          } else {
            show_active_hotspots();
          }

          video_ended = true;
        }, 1500);
      }


      //Only do this if the video has ended (page load). Also called in video ended call back
      if (video_ended) {
        if (page_has_tlc) {
          show_page_tlc();
        } else {
          show_active_hotspots();
        }
      }

      update_breadcrumbs(new_img);
    }, 1250);

  }//end hash_change


  /**
   * Build up the breadcrumbs.
   *
   *  - Called in hash_change
   *
   *
   * @param active_img
   * @returns {boolean}
   */
  function update_breadcrumbs (active_img) {

    var nav_list = elems.nav_breadcrumbs.find('ul');
    var img_label = active_img.data('page-label');

    //Build up the breadcrumbs:
    if (img_label === 'Home') {
      nav_list.html('');
      elems.page_nav_back.removeClass('active');
      bc_active = false;
      return false;
    }

    var parent = '';
    if (typeof active_img.data('parent-page') !== 'undefined') {
      parent = active_img.data('parent-page');
    }

    var home_link = '<li><a href="#">Home</a></li>';
    var parent_link = (parent.length) ? '<li><a href="#'+parent+'">'+$('img.'+parent).data('page-label')+'</a></li>' : '';
    var this_link = '<li><strong>'+active_img.data('page-label')+'</strong></li>';


    nav_list.html(home_link+parent_link+this_link);

    elems.nav_breadcrumbs.addClass('active');

    bc_active = true;

    set_back_btn_link();

    if (!page_has_tlc) {
      elems.page_nav_back.addClass('active');
    }

  }//end update_breadcrumbs


  /**
   * Check the page to handle the video:
   *
   *  - Called from home_init()
   *
   *  - If no hash, we're on the homepage
   *    - Check if vid has played (check cookie).
   *
   *  - If not on homepage, or video has played. Set video_is_active to false and hide it.
   */
  function check_page () {
    var hash = window.location.hash;
    var video_played = get_cookie('video_played');

    if (hash.length || video_played) {
      video_is_active = false;
      $('div#video-wrap').addClass('hide-video');
    }
  }//end check_page


  /**
   * Hide the preloader
   */
  function hide_preloader () {
    $('body').removeClass('body-ofh');

    //Make sure we're at the top of the page:
    window.scrollTo(0, 0);

    elems.preloader.addClass('hide-preloader');
  }//end hide_preloader


  /**
   *
   * get_hs_anchor_classes
   *   - Called from homepage init hotspot / infolink click
   *   - Sets all other hotspots to hidden
   *
   * @param hs
   * @returns {string}
   */
  function get_hs_anchor_classes (hs) {
    var classes = hs.attr('class');

    //Build up string of classes - add inactive class to hotspots ONLY on this active page
    classes = '.'+classes.split(' ').join('.');

    //Remove the category class:
    if (typeof hs.data('cat') !== 'undefined') {
      classes = classes.replace('.'+hs.data('cat'), '');
    }

    //Remove the active class too so we can hide the other hotspots
    classes = classes.replace('.active', '');

    return classes;
  }//end get_hs_anchor_classes


  /**
   * Set the back button to the last item in the breadcrumbs link
   */
  function set_back_btn_link () {

    //set the link to the last link in the breadcrumbs
    var nav_link = $('ul.nav-items li:last-child').prev().find('a').attr('href');

    elems.page_nav_back.attr('href', nav_link);

  }//end set_back_btn_link


  /**
   * Close an infobox
   *   - Empty the mobile container
   *   - Remove hidden classes from all hotspots
   *
   *
   * @param hotspot
   */
  function close_infobox (hotspot) {

    var info_box_content = hotspot.find('span.infobox-content');

    elems.mobile_hotspot_container_outer.removeClass('active');

    info_box_content.removeClass('active');

    $('.hotspot-anchor.hs-page-infobox').removeClass('hs-page-items-inactive active');

    setTimeout(function () {
      info_box_content.css('z-index', '-1');

      elems.mobile_hotspot_container.html('');
      elems.mobile_hotspot_container_outer.addClass('hide');

      if (!elems.filter.hasClass('no-categories')) {
        elems.filter.addClass('filter-active');
      }

    }, 500);

  }//end close_infobox


  /**
   * When we navigate we need to close all infoboxes on mobile
   */
  function reset_mobile_info_boxes () {

    elems.mobile_hotspot_container_outer.removeClass('active');
    $('.hotspot-anchor.hs-page-infobox').removeClass('hs-page-items-inactive active');

    setTimeout(function () {

      elems.mobile_hotspot_container.html('');
      elems.mobile_hotspot_container_outer.addClass('hide');

    }, 500);

  }//end reset_info_boxes


  /**
   * Add the categories classes for the filter to the top level hotspots (hotspots that trigger a hash change).
   *
   *  - Called from homepage_init()
   */
  function set_top_level_hotspot_category_classes () {

    //Add the inner hotspot categories to the top level page hotspots
    var top_level_hs = $('.hs-page-link');
    if (top_level_hs.length) {

      top_level_hs.each(function () {

        var $top_level = $(this),
          href = $top_level.attr('href').replace('#', '');

        var inner_hs = $('.hotspot-anchor.' + href);
        if (inner_hs.length) {
          inner_hs.each(function () {

            var cat = $(this).data('cat');
            if (typeof cat !== 'undefined' && !$top_level.hasClass(cat)) {
              $top_level.addClass(cat);
            }

          });//end each inner-hs
        }//end if inner hs len

      });//end each top level hs

    }//end if top level hs len

  }//end set_top_level_hotspot_category_classes


  /**
   *
   * When the video has ended:
   *   - Add ended class.
   *   - Add class hide
   *   - Set global var video_ended to true
   *   - Set the cookie.
   *   - How TLC or hotspots
   *
   * When it starts playing hide the preloader
   *   - Called from homepage_init()
   *
   */
  function handle_video () {

    if (!$('#video-wrap').hasClass('hide-video')) {

      $('#video-wrap video').on('ended', function () {
        $('#video-wrap').addClass('ended');
        setTimeout(function () {
          $('#video-wrap').addClass('hide');
          video_ended = true;

          //Set video_played cookie, checked in check_page()
          set_cookie('video_played', 1, 1);

          if (page_has_tlc) {
            show_page_tlc();
          } else {
            show_active_hotspots();
          }

        }, 650);
      });

      $('#video-wrap video').on('playing', function () {
        setTimeout(function () {
          hide_preloader();
        }, 150);
      });
    }

  }//end handle_video


  /**
   * Handle the hotspot anchor or breadcrumbs anchor clicks
   */
  function handle_navigation ($this) {

    //Check for active filters. If this link does't have a filter that matches the active one, then unset filters.
    if ($('body').hasClass('filter-active')) {
      //Get this classes:
      var classes = $this.attr('class'),
          reset_filter = true;

      if (typeof classes !== 'undefined' && classes.length) {
        classes = classes.split(' ');

        //Loop THIS hs anchor classes. If body doesn't have any of these classes then reset filter
        if (classes && classes.length) {
          for (var i=0; i<classes.length; i++) {
            if ($('body').hasClass(classes[i])) {
              reset_filter = false;
            }
          }
        }
      }//end if classes len



      //Reset filter
      if (reset_filter) {
        $('div.filter ul li a.active').trigger('click');
      }

    }//end if body has class filter-active

  }//end handle_navigation


  /**
   * When we click on a hotspot that shows an info box
   *
   * @param $this
   * @param parent
   * @returns {boolean}
   */
  function handle_infobox_click ($this, parent) {

    //Already open? Close it
    if (parent.hasClass('active')) {
      close_infobox(parent);
      return false;
    }

    //Add active class
    parent.addClass('active');


    //Load into container on touch devices - small screens
    if ($('html').hasClass('touchevents') && parseInt($(window).width()) <= 896) {
      $('.hotspot-anchor.hs-page-infobox').addClass('hs-page-items-inactive');
      elems.mobile_hotspot_container_outer.removeClass('hide').addClass('active');
      elems.mobile_hotspot_container.html(parent.html());
      return false;
    }

    //Hide all other hotspots:
    var classes = get_hs_anchor_classes(parent);
    $(classes+':not(.active)').addClass('hs-page-items-inactive');

    //Hide the filter
    elems.filter.removeClass('filter-active');

    $('span.infobox-content').removeClass('active').css('z-index', '-1');

    parent.find('span.infobox-content').css('z-index', 10).addClass('active');

  }//end handle_infobox_click


  /**
   * If we click an anchor within an infobox
   * @param $this
   */
  function handle_infobox_anchor_click ($this) {

    var link = $this.data('link'),
        infobox_page_link_holder = $('a.infobox-link-holder');

    infobox_page_link_holder.attr('href', link);
    if (typeof $this.data('target') !== 'undefined') {
      infobox_page_link_holder.attr('target', '_blank');
    } else {
      infobox_page_link_holder.removeAttr('target');
    }

    infobox_page_link_holder[0].click();

  }//end handle_infobox_anchor_click


  /**
   * When we close a TLC info box;
   *   - Remove and add a shown-once class so we don't show it again.
   *   - Show the active hotspots.
   *   - If breadcrumbs are active, show them.
   *
   * @param $this
   */
  function handle_tlc_box_close ($this) {

    //@@NOTE: A few changes here: cookie is not working on parent site when in iFrame. This meant we couldn't close the second homepage modal.
            //To fix we set a class on first modal close, check it for the second and then close. Still set the cookie for other purposes though.

    //If first time on landing show additional content on first close.
    //var house_tlc_shown = get_cookie('house_tlc_shown');
    var house_tlc = $('.top-level-content-outer.tlc-house div.inner');
    //if ($('img.page-image.active-img').data('img-id') === 'house' && !house_tlc_shown) {
    if ($('img.page-image.active-img').data('img-id') === 'house' && !house_tlc.hasClass('first-shown')) {
      set_cookie('house_tlc_shown', 1, 1);
      //var house_tlc = $('.top-level-content-outer.tlc-house div.inner');
      house_tlc.addClass('first-shown');
      var new_content = '<p class="text-center">Click on an area of the house to explore measures you can incorporate to improve your home.</p>' +
        '<p><img alt="Image of house roof and instructional arrow" class="tlc-image half desktop" src="/wp-content/themes/BradfordsSustainability/dist/images/house-tlc-1.jpg"><img class="tlc-image half desktop" src="/wp-content/themes/BradfordsSustainability/dist/images/house-tlc-2.jpg"><img class="tlc-image half mobile" src="/wp-content/themes/BradfordsSustainability/dist/images/house-tlc-1-mobile.jpg"><img alt="Image of house roof and instructional arrow" class="tlc-image half mobile" src="/wp-content/themes/BradfordsSustainability/dist/images/house-tlc-2-mobile.jpg"></p>';
        new_content += '<br/><p class="text-center desktop">Filter by the type of measure you want by clicking on any one of the three buttons at the bottom of the house.</p>';
        new_content += '<p><img alt="Image of category filters" class="tlc-image desktop" src="/wp-content/themes/BradfordsSustainability/dist/images/house-tlc-3.jpg"></p>';
        new_content += '<p class="text-center"><strong>Close this box to activate the interactive site.</strong></p>';

      house_tlc.html(new_content);

      return false;
    }

    var parent = $this.parent('div').parent('div.top-level-content-outer');

    parent.addClass('hide');
    setTimeout(function () {
      parent.removeClass('show hide').addClass('shown-once');

      show_active_hotspots();

      if (bc_active) {
        elems.page_nav_back.addClass('active');
      }

    }, 450);

  }//end handle_tlc_box_close


  /**
   * When we click on a category filter
   *
   *  - If not active:
   *    - Remove all cat classes from body and add this one.
   *  - Else
   *    - Remove active class, remove class from body.
   *
   * @param $this
   * @returns {boolean}
   */
  function handle_category_filter_click ($this) {

    var filter_class = $this.attr('href'),
          body = $('body');

    if ($this.hasClass('disabled')) {
      return false;
    }

    if ($this.hasClass('active')) {
      $this.removeClass('active');
      body.removeClass('filter-active').removeClass(filter_class);
    } else {

      //Reset body and filter items:
      body.removeClass('hee h_e wc');
      elems.filter_item.not(this).removeClass('active');

      $this.addClass('active');
      body.addClass('filter-active').addClass(filter_class);
    }


  }//end handle_category_filter_click


  /**
   * Set up events for the main homepage functionality.
   */
  function homepage_init () {

    //Add category classes to anchor hotspots
    set_top_level_hotspot_category_classes();


    //Set the position of the cat filter
    position_filter();


    //Hide the video on page load if we're not on the home page
    check_page();


    //Check for a hash value, load the appropriate image and show / hide the video
    hash_change();


    //Show the preloader logo when it has loaded
    imagesLoaded('img.preloader-img', {background: false}, function () {
      $('img.preloader-img').addClass('show-logo');
    });


    //Detect when video ended
    handle_video();


    //Win resizes events
    $(window).resize(function () {
      set_hotspot_locations();
      position_filter();
    });


    //Trigger has_change
    $(window).on('hashchange', function (e) {
      hash_change();
    });


    //Hotspot transition / page click
    $(document).on('click', 'a.hs-page-link, nav.page-breadcrumbs li a', function (e) {
      handle_navigation($(this));
      window.location.hash = $(this).attr('href');
      e.preventDefault();
      return false;
    });


    //Hotspot infobox click
    $(document).on('click', '.hotspot-anchor.hs-page-infobox span.hs-label', function(e){

      e.preventDefault();

      handle_infobox_click($(this), $(this).parent());

      return false;

    });


    //Touch device / mobile hotspot close click
    $(document).on('click', '.mobile-hotspot-container span.infobox-close', function(){

      var $this = $(this),
          info_box_content = $this.parent('span.infobox-content');

      elems.mobile_hotspot_container_outer.removeClass('active');

      info_box_content.removeClass('active');
      $('.hotspot-anchor.hs-page-infobox').removeClass('hs-page-items-inactive active');
      setTimeout(function () {
        info_box_content.css('z-index', '-1');

        elems.mobile_hotspot_container.html('');
        elems.mobile_hotspot_container_outer.addClass('hide');

      }, 500);

    });


    //Rotate device icon animation
    setInterval(function () {
      $('img.rotate-img').toggleClass('rotate')
    }, 1500);


    //Video popups
    $(document).on('click', '.video-link', function () {
      var src = $(this).data('video-link');

      //Add it to the main video link and trigger the click
      $('a.page-video-link').attr('href', src).trigger('click');
    });
    $('.video').magnificPopup({
      type: 'iframe',
      preloader: true,
      removalDelay: 450, //delay removal by X to allow out-animation
      callbacks: {
        beforeOpen: function () {
          this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
          this.st.mainClass = 'mfp-video-open';//this.st.el.attr('data-effect');
        },
        beforeClose: function () {
          $('div.mfp-wrap').removeClass('mfp-video-open');
        }
      },
      closeOnContentClick: true
    });//end video modal


    //Infobox link click
    $(document).on('click', 'span.infobox-link', function () {
      handle_infobox_anchor_click($(this));
    });


    //Image popups
    $('.magnific-gallery').each(function () {
      $(this).magnificPopup({
        delegate: 'a',
        type: 'image',
        preloader: true,
        gallery: {
          enabled: true
        },
        removalDelay: 500, //delay removal by X to allow out-animation
        callbacks: {
          beforeOpen: function () {
            // just a hack that adds mfp-anim class to markup
            this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
            this.st.mainClass = this.st.el.attr('data-effect');
          }
        },
        closeOnContentClick: true,
        midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
      });
    });//end image modal


    //Close TLC box
    $('span.tlc-close').click(function () {
      handle_tlc_box_close($(this));
    });


    //Filter click
    elems.filter_item.click(function(e){

      e.preventDefault();
      handle_category_filter_click($(this));

    });


    //House page next button click - trigger TLC close:
    $('a#house-next').click(function(e){

      e.preventDefault();

      handle_tlc_box_close(null);

      return false;

    })


  }//end homepage init


  /**
   * Position the filter
   *
   *  - Called from init and window.resize
   *
   *
   */
  function position_filter () {

    var win_h = parseInt($(window).height()),
        hh = parseInt($('header.header').outerHeight()),
        active_img_h = (parseInt($('img.page-image.active-img').height())),
        test_img_h = (active_img_h + hh);

    var height;
    /*if (test_img_h < win_h) {
      height = active_img_h;
    } else {
      height = (win_h - hh);
    }*/
    height = active_img_h;

    $('div.filter-container').height(height);

  }//end position_filter


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {

    'common': {
      init: function() {

        //Mobile nav menu click
        $(document).on('click', 'div.hamburger', function () {
          $(this).toggleClass('is-active');
          $('div.navbar-collapse').toggleClass('collapse');
          $('nav.navbar').toggleClass('active');
        });


        //Close the mobile menu on body click
        $(document).on('click', 'body > main', function () {
          if ($('div.hamburger').hasClass('is-active')) {
            $('div.hamburger').trigger('click');
          }
        });

      }
    },

    'home': {
      init: function() {
        homepage_init();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
